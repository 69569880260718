// src/ServicesGrid.js
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './ServicesGrid.css';

const services = [
  {
    title: "LLM-Powered WhatsApp Commerce",
    description: "Transform Customer Engagement with AI-Driven WhatsApp Solutions",
    link: "./services#whatsappchatbot",
    image: "home/whatsappchatbot.png"
  },  {
    title: "Generative AI Solutions",
    description: "Elevate Your Business with Intelligent, Context-Aware Systems",
    link: "./services#aisolutions",
    image: "home/aisolutions.png"
  },
  {
    title: "Technology Leadership and Strategy Services",
    description: "Transform your tech strategy and operations",
    link: "services#technologyleadership",
    image: "home/technologyleadership.png"
  },
  {
    title: "Building Right Size Solutions",
    description: "Transform challenges into opportunities for innovation and success",
    link: "services#buildingrightsizesolutions",
    image: "home/buildingrightsizesolutions.png"
  },
  {
    title: "Product Engineering and Application Development",
    description: "Accelerate Innovation with Product Engineering and Application Development",
    link: "./services#productengineering",
    image: "home/productengineering.png"
  },
  {
    title: "Mobile Application Design and Development",
    description: "Empower Your Mobile Vision with Strategic Development",
    link: "./services#mobileappdevservices",
    image: "home/mobileappdevservices.png"
  },
  {
    title: "Cloud Strategy and Optimization",
    description: "Elevate Your Cloud Journey",
    link: "./services#cloudstrategyoptimization",
    image: "home/cloudstrategyoptimization.png"
  },
  {
    title: "DevOps and MLOps Services",
    description: "Accelerate and Innovate with DevOps and MLOps Consulting",
    link: "./services#devopsmlopsservices",
    image: "home/devopsmlopsservices.png"
  },
  {
    title: "Cyber Security Services",
    description: "Secure Your Digital Future",
    link: "./services#cybersecurityservices",
    image: "home/cybersecurityservices.png"
  }
];

const ServicesGridSmall = () => {
  return (
    <div className="services-image-grid">
      {services.map((service, index) => (
        <HashLink to={service.link}>
          <img src={service.image} alt={service.title} className="service-grid-image" />
        </HashLink>
      ))}
    </div>
  );
};

const ServicesGrid = () => {
  return (
    <section className="services-grid">
      <div className="grid-container">
        {services.map((service, index) => (
          <div className="grid-item" key={index}>
            <h3>{service.title}</h3>
            <HashLink to={service.link} className="read-more">READ MORE</HashLink>
          </div>
        ))}
      </div>
    </section>
  );
};

export { ServicesGrid, ServicesGridSmall };